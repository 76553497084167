* {
  box-sizing: border-box;
}

html {
  --bg: #fff;
  --fg: #25282A;
  --gray: #888;
  --royal: #0072CE;
  --blue: #6AD1E3;
  --primary: #D1CCBD;
  --secondary: var(--royal);
  --tertiary: #FF585D;

  background-color: var(--bg);
  color: var(--fg);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
  font-size: calc(.65em + 0.7vw);
  line-height: 1.3;

  ::selection {
    background: var(--secondary);
    color: var(--bg);
  }

  @media (prefers-color-scheme: dark) {
    --bg: #25282A;
    --fg: #fff;
    --secondary: var(--blue);

    ::selection {
      background: var(--secondary);
      color: var(--fg);
    }
  }
}

body {
  margin: 0 auto;
  padding: 0 1em;
  max-width: 40em;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

header {
  margin-bottom: 1rem;
}

.highlight {
  align-items: center;
  display: flex;
  margin-bottom: 0.5em;
  width: 100%;
  label {
    margin-right: 0.5em;
  }
  input {
    caret-color: var(--secondary);
  }
}

label {
  color: var(--gray);
}

button, .highlight {
  border-radius: 5px;
  border-color: var(--primary);
  border: 0.1em solid var(--primary);
  padding: 0.5em 1em;

  &:hover, &:focus, &:focus-within {
    border-color: var(--secondary);
  }
}

input {
  border: none;
  flex: 1;
  &:read-only {
    color: var(--primary)
  }
}

input[type="number"] {
  text-align: center;
  border-bottom: 0.1em solid var(--primary);
  margin: 0 1em;
  width: 4em;
  padding-left: 0.5em;
  &:hover, &:focus {
    border-color: var(--secondary);
  }
}

button, input {
  background: transparent;
  color: inherit;
  font: inherit;
  outline: none;
}

button {
  position: relative;
  transition: top 50ms;
  &:hover, &:focus {
    top: -1px;
    cursor: pointer;
  }
  &:active {
    background: var(--secondary);
    border-color: var(--secondary);
    top: 1px;
  }
}

.is-premium {
  border-left: 0.25em solid var(--secondary);
  padding-left: 0.25em;
  margin-left: -0.5em;
}
